import React, { useState } from "react"
import styled from "styled-components"

//Rotacion flecha de Submenu
const IconButtonWrapper = styled.div`
  float: right;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.3s ease-out;
  -webkit-transform: ${props =>
    props.rotate === "true" ? `rotate(180deg)` : ""};
`

//Boton Hamburguesa
const AccordionRight = ({ data, title, description, list }) => {
  //Desplegar primera pregunta
  const [activateRequestOne, setActivateRequestOne] = useState(true)

  //Desplegar segunda pregunta
  const [activateRequestTwo, setActivateRequestTwo] = useState(false)

  //Desplegar tercera pregunta
  const [activateRequestThree, setActivateRequestThree] = useState(false)

  //Desplegar cuarta pregunta
  const [activateRequestFour, setActivateRequestFour] = useState(false)

  //Desplegar quinta pregunta
  const [activateRequestFive, setActivateRequestFive] = useState(false)

  const clickQuestionOne = event => {
    event.preventDefault()
    setActivateRequestOne(!activateRequestOne)
    //Cerrar submenuSoluciones
    setActivateRequestTwo(false)
    setActivateRequestThree(false)
    setActivateRequestFour(false)
    setActivateRequestFive(false)
  }

  //Desplegar segunda pregunta
  const clickQuestionTwo = event => {
    event.preventDefault()
    setActivateRequestTwo(!activateRequestTwo)
    //Cerrar submenuProductos
    setActivateRequestOne(false)
    setActivateRequestThree(false)
    setActivateRequestFour(false)
    setActivateRequestFive(false)
  }
  //Desplegar tercera pregunta
  const clickQuestionThree = event => {
    event.preventDefault()
    setActivateRequestThree(!activateRequestThree)
    //Cerrar submenuProductos
    setActivateRequestOne(false)
    setActivateRequestTwo(false)
    setActivateRequestFour(false)
    setActivateRequestFive(false)
  }
  //Desplegar cuarta pregunta
  const clickQuestionFour = event => {
    event.preventDefault()
    setActivateRequestFour(!activateRequestFour)
    //Cerrar submenuProductos
    setActivateRequestOne(false)
    setActivateRequestTwo(false)
    setActivateRequestThree(false)
    setActivateRequestFive(false)
  }
  //Desplegar quinta pregunta
  const clickQuestionFive = event => {
    event.preventDefault()
    setActivateRequestFive(!activateRequestFive)
    //Cerrar submenuProductos
    setActivateRequestOne(false)
    setActivateRequestTwo(false)
    setActivateRequestThree(false)
    setActivateRequestFour(false)
  }

  return (
    <section className="container-accordionRight">
     <div className="container-accordionRight-title">
       {title}
      </div>
      {description ?
      <div className="container-accordionRight-description">
       {description}
      </div> :"" }
      <div className="container-accordionRight-info">
        <ul className={list ? "container-accordionRight-info-list container-accordionRight-info-list-true " : "container-accordionRight-info-list"}>
          <List
            onClick={clickQuestionOne}
            activate={activateRequestOne}
            title={data[0].title}
            image={data[0].image}
            description={data[0].description}
          />
          <List
            onClick={clickQuestionTwo}
            activate={activateRequestTwo}
            description={data[1].description}
            title={data[1].title}
            image={data[1].image}
          />
          <List
            onClick={clickQuestionThree}
            activate={activateRequestThree}
            description={data[2].description}
            title={data[2].title}
            image={data[2].image}
          />
          <List
            onClick={clickQuestionFour}
            activate={activateRequestFour}
            description={data[3].description}
            title={data[3].title}
            image={data[3].image}
          />
          {data[4] ?   <List
            onClick={clickQuestionFive}
            activate={activateRequestFive}
            description={data[4].description}
            title={data[4].title}
            image={data[4].image}
          /> : ""
        }
        
        </ul>
        <div className="container-accordionRight-info-imagen">
          {!activateRequestOne &&
            !activateRequestTwo &&
            !activateRequestThree &&
            !activateRequestFour &&
            !activateRequestFive ? (
            <div className="">
              <img src={data[0].image} alt="solucion" />
            </div>
          ) : null}

          {activateRequestOne ? (
            <div className="">
              <img src={data[0].image} alt="solucion" />
            </div>
          ) : null}

          {activateRequestTwo ? (
            <div className="">
              <img src={data[1].image} alt="solucion" />
            </div>
          ) : null}
          {activateRequestThree ? (
            <div className="">
              <img src={data[2].image} alt="solucion" />
            </div>
          ) : null}

          {activateRequestFour ? (
            <div className="">
              <img src={data[3].image} alt="solucion" />
            </div>
          ) : null}

          {activateRequestFive ? (
            <div className="">
              <img src={data[4].image} alt="solucion" />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}
export default AccordionRight

const IconMenu = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.87892 7.32548C0.878349 7.22398 0.897264 7.12337 0.934582 7.02941C0.971899 6.93545 1.02688 6.84998 1.09638 6.77792C1.16609 6.70564 1.24903 6.64827 1.34041 6.60912C1.43179 6.56996 1.5298 6.5498 1.62879 6.5498C1.72778 6.5498 1.8258 6.56996 1.91718 6.60912C2.00855 6.64827 2.09149 6.70564 2.1612 6.77792L8.28765 13.0786C8.70946 13.5119 9.28123 13.7552 9.87738 13.7552C10.4735 13.7552 11.0453 13.5119 11.4671 13.0786L17.5936 6.77792C17.7348 6.6327 17.9263 6.55112 18.126 6.55112C18.3257 6.55112 18.5172 6.6327 18.6584 6.77792C18.7996 6.92314 18.8789 7.1201 18.8789 7.32548C18.8789 7.53085 18.7996 7.72781 18.6584 7.87303L12.5319 14.1737C12.1836 14.5328 11.7699 14.8177 11.3144 15.0121C10.8589 15.2065 10.3705 15.3066 9.87738 15.3066C9.38421 15.3066 8.89588 15.2065 8.44037 15.0121C7.98486 14.8177 7.57112 14.5328 7.22283 14.1737L1.09638 7.87303C1.02688 7.80097 0.971899 7.7155 0.934582 7.62154C0.897264 7.52758 0.878349 7.42697 0.87892 7.32548Z"
        fill="#333333"
      />
    </svg>
  )
}

const List = ({ onClick, activate, title, description, image }) => {
  return (
    <li className="container-accordionRight-info-list-item">
      <div
        className="container-accordionRight-info-list-item-title"
        onClick={event => {
          onClick(event)
        }}
      >
       <h2>{title}</h2>
        <div className="">
          <IconButtonWrapper rotate={activate.toString()}>
            <IconMenu />
          </IconButtonWrapper>
        </div>
      </div>
      {activate ? (
        <div>
          <div className="container-accordionRight-info-list-item-description">
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="container-accordionRight-info-list-item-image">
            <img src={image} alt="solucion" />
          </div>
        </div>
      ) : (
        false
      )}
    </li>
  )
}
