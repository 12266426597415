/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import LaMatier from "@components/sharedComponents/customersLogos/logos/LaMatier.svg"
import Ultralon from "@components/sharedComponents/customersLogos/logos/Ultralon.svg"
import Chiolecca from "@components/sharedComponents/customersLogos/logos/Chiolecca.svg"
import CasaRossello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import Leoncito from "@components/sharedComponents/customersLogos/logos/Leoncito.svg"
import MascotaVeloz from "@components/sharedComponents/customersLogos/logos/MascotaVeloz.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/embudo-ventas-ecommerce.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/plantillas-whatsapp-ecommerce.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/plan-ventas-ecommerce.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/fidelizar-clientes-era-digital.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/script-chat-live.jpg"

// logos testimonios
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import CyCTes from "@components/sharedComponents/testimonies/images/logos/c&c.svg"

//foto testimonio desktop
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"
import cycDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/c&c.png"

//foto testimonio desktop
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"
import cycMobile from "@components/sharedComponents/testimonies/images/fotosMobile/c&c.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageIndustrias/pageEcommerce/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageIndustrias/pageEcommerce/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageIndustrias/pageEcommerce/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageIndustrias/pageEcommerce/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageEcommerce.jpg"

import { TitleUnderlineEcommerceOne, TitleUnderlineEcommerceTwo } from "@components/pageIndustrias/components/intro/titleUnderlineEcommerce"
import imageStartEcommerce from "@components/pageIndustrias/components/intro/images/imageStartEcommerce.webp"

import ImageVerticalOne from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageIndustrias/pageEcommerce/images/imagesNavbarVertical/imageFour.webp"

const titleStart = (
  <p>
    Aumenta la conversión de tu  <TitleUnderlineEcommerceOne underline="ecommerce" />  creando experiencias únicas
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Fideliza y genera más ventas mediante una solución omnicanal impulsada con inteligencia artificial que te permite personalizar cada fase de compras del cliente en todos tus canales de atención.
        <br /><br />
        Automatiza tus procesos mediante chatbots conversacionales, envía notificaciones personalizadas de ofertas y gestión de envíos, escala y agiliza el soporte de tu tienda online en simples pasos.
      </p>
    ),
    image: imageStartEcommerce,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    LaMatier,
    Ultralon,
    Chiolecca,
    CasaRossello,
    Leoncito,
    MascotaVeloz
  ],
  customersTablet: {
    arrayOne: [LaMatier, Ultralon, Chiolecca, CasaRossello, Leoncito, MascotaVeloz,]
  },
  articles: [
    {
      image: article1,
      title: "¿Cómo acelerar el embudo de ventas de un e-commerce?",
      textbody:
        "Te enseñamos cómo el embudo de ventas de tu e-commerce puede ser mucho más rápido para incrementar tus ventas digitales.",
      link: "https://beexcc.com/blog/embudo-ventas-ecommerce/",
      card: true,
    },
    {
      image: article2,
      title: "12 ejemplos de plantillas de WhatsApp para e-commerce",
      textbody:
        "Si buscas plantillas de mensajes para WhatsApp para tu e-commerce, aquí te damos 12. Además, aprenderás a como configurarlas.",
      link: "https://beexcc.com/blog/plantillas-whatsapp-ecommerce/",
      card: true,
    },
    {
      image: article3,
      title: "¿Cómo mejorar un plan de ventas de tu e-commerce?",
      textbody:
        "Te contamos cuáles son los pasos que debes seguir para mejorar el plan de ventas de un e-commerce para incrementar ventas de tu negocio.",
      link: "https://beexcc.com/blog/plan-ventas-ecommerce/",
    },
    {
      image: article4,
      title: "Fidelizar clientes en la era digital: 7 estrategias para lograrlo",
      textbody:
        "Descubre cuáles son los pasos que debes seguir para fidelizar clientes en la era digital y aumentar tu rentabilidad.",
      link: "https://beexcc.com/blog/fidelizar-clientes-era-digital/",
    },
    {
      image: article5,
      title: "¿Cómo crear un script de live chat para tu página web?",
      textbody:
        "Descubre cuáles son los pasos para crear un script de live chat y cómo implementarlo en la página web de tu negocio gracias a Beex.",
      link: "https://beexcc.com/blog/script-chat-live/",
    },
  ],
  contacts: {
    title: "Aumenta las conversiones y fideliza más clientes en tu e-commerce",
    textbody: "Estás a un paso de revolucionar tus estrategias de marketing y ventas de tu e-commerce. Agenda una reunión para que uno de nuestros agentes se involucre con tus objetivos.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CasaRosselloTes,
      title:
        "Con Beex, hemos aumentado en +75% nuestros leads desde canal digital",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing de Casa Rosselló",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CyCTes,
      title:
        "Con la implementación de bot en WhatsApp, hemos aumentado en +40% nuestras ventas digitales",
      textbody:
        "C&C, minorista en comercialización de artículos de tecnología, ha aumentado sus ventas en +40% con Beex, potenciando su remarketing y crosseling por WhatsApp.",
      name: "Oskar Martinez",
      post: "Marketing Leader en C&C",
      link: "/casos-de-exito/cyccomputer/",
      imgDesktop: cycDesktop,
      imgMobile: cycMobile,
      metrics: [
        {
          metric: "+40%",
          descripcion: "aumento de ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "<1",
          descripcion: "minuto tiempo de primera respuesta",
          color: "#5831D8",
        },
      ],
    },
  ],
  accordionRight: [
    {
      title: "Chatbots con IA",
      description: "<p>Maximiza la eficiencia de tu e-commerce con nuestros chatbots integrados a ChatGPT. Diseña flujos rápidos de conversación para resolver consultas frecuentes, asiste en procesos de compra y ofrece un autoservicio 24/7. Haz que tus ventas aumenten mediante una experiencia del cliente simplificada.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Seguimiento de pedidos",
      description: "<p>Permite que los clientes puedan verificar el estado de sus pedidos en tiempo real desde cualquier canal. Además, configura notificaciones personalizadas por cada estado de envío, como la confirmación del pedido, el envío del producto y la entrega programada. Todo esto sin la intervención de una agente.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Notificaciones personalizadas",
      description: "<p>Automatiza el envío de notificaciones en fases clave de compra como el lanzamiento de un nuevo producto, promociones, códigos de seguridad, recordatorio de abandono de carrito, entro otros. Configura rápidamente estos mensajes para aumentar el compromiso del cliente y fidelizar más.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Integración con herramientas externas",
      description: "<p>Agiliza la gestión de tu e-commerce al integrar plataformas como Shopify, Woocommerce o WordPress, o con CRM como Zoho, HubSpot o Salesforce; optimizando así la automatización de procesos de compra, la gestión de clientes y el análisis de datos para impulsar el crecimiento de tu negocio.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "Cómo utilizar las plantillas de WhatsApp en un e-commerce",
    description: "<p>Descubre cómo aprovechar al máximo las plantillas de mensajes de WhatsApp en tu e-commerce. Aprende a crear mensajes eficientes para mejorar la comunicación con tus clientes y potenciar las ventas en tu empresa. <br /><br /> Nuestro Marketing Manager, Jorge Bautista, nos cuenta cómo crear este tipo de mensajes y los casos de uso.</p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Envío de mensajes masivos",
      text: (
        <p>
       Segmenta tus listas de contactos para enviar mensajes personalizados basados en los intereses y comportamientos de compra de tus clientes como promociones, descuentos especiales, eventos o cualquier otra información relevante.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Mejora de anuncios Click-to-WhatsApp",
      text: (
        <p>
          Mejora los resultados de tus anuncios en Meta Ads dirigidos a WhatsApp implementando chatbots para ofrecer a tus clientes una experiencia más interactiva y personalizada, facilitando la atención al cliente, las compras y la obtención de información precisa.
        </p>
      ),
      image: ImageVerticalTwo,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "3",
      title: "Encuestas de satisfacción",
      text: (
        <p>
          Envía encuestas de satisfacción a tus clientes por WhatsApp después de que hayan realizado una compra o consulta. Esto te ayuda a recopilar información valiosa que puedes utilizar para mejorar tu atención y fidelizar a tus clientes.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "4",
      title: "Autenticación de todos tus usuarios",
      text: (
        <p>
          Utiliza WhatsApp como un canal cercano para proporcionar una capa adicional de seguridad para las cuentas de tus clientes, permitiendo la autenticación de dos factores a través de mensajes cifrados y verificados.
        </p>
      ),
      image: ImageVerticalOne,
    },

  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Envío de mensajes masivos",
      text: (
        <p>
       Segmenta tus listas de contactos para enviar mensajes personalizados basados en los intereses y comportamientos de compra de tus clientes como promociones, descuentos especiales, eventos o cualquier otra información relevante.
        </p>
      ),
      image: ImageVerticalThree,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Mejora de anuncios Click-to-WhatsApp",
      text: (
        <p>
          Mejora los resultados de tus anuncios en Meta Ads dirigidos a WhatsApp implementando chatbots para ofrecer a tus clientes una experiencia más interactiva y personalizada, facilitando la atención al cliente, las compras y la obtención de información precisa.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Encuestas de satisfacción",
      text: (
        <p>
          Envía encuestas de satisfacción a tus clientes por WhatsApp después de que hayan realizado una compra o consulta. Esto te ayuda a recopilar información valiosa que puedes utilizar para mejorar tu atención y fidelizar a tus clientes.
        </p>
      ),
      image: ImageVerticalFour,
    },
    {
      tab: "4",
      title: "Autenticación de todos tus usuarios",
      text: (
        <p>
          Utiliza WhatsApp como un canal cercano para proporcionar una capa adicional de seguridad para las cuentas de tus clientes, permitiendo la autenticación de dos factores a través de mensajes cifrados y verificados.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
  ],
}

export default data
