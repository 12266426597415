import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="236" height="10" viewBox="0 0 236 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M43.8761 10C38.9969 9.07169 3.27025 10.1081 0.673428 9.82831C-1.44664 9.45317 1.94141 2.84689 3.90932 1.87407C4.5991 1.52436 5.40046 1.47351 7.2365 1.63247C10.1478 1.93131 47.7916 1.33997 47.7916 1.33997C48.2887 1.54344 49.7798 0.856744 50.0537 1.10472C50.9159 1.68332 111.931 0.894891 117.135 0.8631C120.29 0.844025 117.794 1.23824 121.507 1.07928C129.419 0.761364 146.664 1.06657 152.659 0.704142C157.457 0.405302 158.491 0.392594 163.097 0.63421C167.803 0.850392 192.828 0.538832 195.689 0.074676C196.389 -0.0524901 196.926 -0.00797814 196.926 0.138263C196.926 0.170054 214.567 0.278142 214.648 0.392591C214.78 0.538832 215.317 0.608769 215.814 0.507036C216.737 0.354437 235.514 -0.198737 235.808 0.227269C236.731 1.44806 234.144 6.64915 231.963 7.91446C229.732 9.26878 217.478 7.74915 207.628 8.56302L161.768 8.64568L151.939 9.15433C150.813 8.71561 146.481 9.82196 143.864 9.03353C142.758 8.72197 134.948 8.67745 133.223 9.00809C131.722 9.26242 91.7652 8.62023 87.6671 9.50403C80.3635 8.76647 50.7435 9.82197 43.8761 10Z" fill="#FFA700" />
    </svg>
  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="263" height="10" viewBox="0 0 263 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.8958 10C43.4584 9.07169 3.64439 10.1081 0.750473 9.82831C-1.61214 9.45317 2.16352 2.84689 4.35657 1.87407C5.12527 1.52436 6.01831 1.47351 8.0644 1.63247C11.3088 1.93131 53.2593 1.33997 53.2593 1.33997C53.8132 1.54344 55.475 0.856744 55.7802 1.10472C56.741 1.68332 124.737 0.894891 130.536 0.8631C134.052 0.844025 131.271 1.23824 135.408 1.07928C144.226 0.761364 163.443 1.06657 170.124 0.704142C175.471 0.405302 176.624 0.392594 181.756 0.63421C187.001 0.850392 214.889 0.538832 218.077 0.074676C218.857 -0.0524901 219.456 -0.00797814 219.456 0.138263C219.456 0.170054 239.115 0.278142 239.205 0.392591C239.352 0.538832 239.951 0.608769 240.505 0.507036C241.534 0.354437 262.458 -0.198737 262.786 0.227269C263.815 1.44806 260.932 6.64915 258.502 7.91446C256.015 9.26878 242.359 7.74915 231.382 8.56302L180.275 8.64568L169.321 9.15433C168.067 8.71561 163.24 9.82196 160.323 9.03353C159.091 8.72197 150.386 8.67745 148.465 9.00809C146.792 9.26242 102.264 8.62023 97.6968 9.50403C89.5576 8.76647 56.5489 9.82197 48.8958 10Z" fill="#FFA700" />
    </svg>
  </span>
)
