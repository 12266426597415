import React from "react"

export const TitleUnderlineEcommerceOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="301" height="11" viewBox="0 0 301 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.9545 10.1977C49.7354 9.24738 4.16995 10.1228 0.85924 9.83129C-1.84313 9.4466 2.50095 2.85562 5.01404 1.89167C5.89495 1.54508 6.9171 1.49784 9.25801 1.66507C12.9697 1.97703 60.9785 1.55535 60.9785 1.55535C61.6117 1.76105 63.5158 1.08108 63.8642 1.33029C64.9617 1.91278 142.777 1.39932 149.413 1.39098C153.436 1.38613 150.253 1.76909 154.988 1.62687C165.079 1.34461 187.07 1.72753 194.717 1.39212C200.837 1.11491 202.156 1.10686 208.028 1.36923C214.03 1.60662 245.945 1.40785 249.595 0.956584C250.488 0.832573 251.173 0.879507 251.173 1.02575C251.173 1.05754 273.669 1.24512 273.772 1.35994C273.939 1.50677 274.625 1.57913 275.259 1.47964C276.437 1.3312 300.384 0.86265 300.757 1.28998C301.93 2.51493 298.613 7.70433 295.827 8.95979C292.977 10.304 277.355 8.72921 264.791 9.49868L206.305 9.37466L193.768 9.83902C192.334 9.39522 186.806 10.482 183.471 9.68182C182.062 9.36528 172.102 9.28557 169.901 9.60843C167.986 9.85599 117.032 9.03373 111.802 9.89906C102.491 9.12859 64.713 10.0506 55.9545 10.1977Z" fill="#FFA700" />
    </svg>
  </span>
)

export const TitleUnderlineEcommerceTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="354" height="10" viewBox="0 0 354 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M65.6761 9.23203C58.3759 8.27791 4.89454 9.12531 1.00845 8.83181C-2.16366 8.44546 2.93111 1.85714 5.88023 0.894735C6.91397 0.548679 8.11368 0.502068 10.8614 0.670737C15.2181 0.984975 71.5677 0.592766 71.5677 0.592766C72.311 0.79886 74.5455 0.120058 74.9545 0.369479C76.2431 0.952642 167.577 0.486966 175.367 0.482701C180.089 0.480313 176.352 0.861324 181.91 0.722006C193.755 0.445945 219.566 0.842363 228.541 0.511653C235.724 0.238195 237.273 0.230961 244.166 0.496935C251.21 0.738014 288.67 0.558829 292.954 0.109807C294.002 -0.0136557 294.806 0.0336999 294.806 0.17994C294.806 0.211731 321.21 0.413129 321.331 0.528007C321.528 0.674944 322.333 0.747724 323.077 0.648621C324.459 0.500906 352.567 0.0470556 353.005 0.474616C354.383 1.70029 350.493 6.88766 347.224 8.14142C343.878 9.48393 325.541 7.89949 310.795 8.66125L242.148 8.50132L227.433 8.95798C225.749 8.51331 219.262 9.59673 215.347 8.79446C213.693 8.47706 202.002 8.39123 199.419 8.71274C197.171 8.95913 137.364 8.10558 131.227 8.96771C120.297 8.19151 75.9562 9.09032 65.6761 9.23203Z" fill="#FFA700" />
    </svg>
  </span>
)